import { extendTheme } from '@chakra-ui/react'

//import { Button } from "./components/Button"
//import { Input } from "./components/Input"
//import { Select } from "./components/Select"
//import { Textarea } from "./components/Textarea"

export const primaryColor = 'purple.600'
export const linkColor = primaryColor
export const codeHighLightBackground = '#3a3a4a'

export const theme = extendTheme({
  //config: {
  //  useSystemColorMode: false,
  //  initialColorMode: "light",
  //},
  //fonts: {
  //  body: "Poppins, sans-serif",
  //  heading: "Poppins, serif",
  //},
  //components: {
  //  Button,
  //  Input,
  //  Select,
  //  Textarea,
  //},
  styles: {
    global: (props: any) => ({
      '.codeStyle, pre, code, code span': {
        fontFamily: 'var(--font-primary)',
        fontStyle: 'normal',
        fontSize: 16,
        border: 'none',
        boxShadow: 'none',
        textShadow: 'none',
        '@media(max-width: 768px)': {
          fontSize: 14,
        },
      },
      '.copyCode': {
        position: 'relative',
        width: '100%',
        button: {
          //backgroundColor: 'grey',
          display: 'none',
          zIndex: 1,
          position: 'absolute',
          top: 5,
          right: 0,
          border: '1px solid #999',
          borderRadius: 5,
          //textTransform: 'uppercase',
          fontSize: 13,
          padding: '.1rem .4rem',
          color: '#999',
        },
        '&:hover': {
          button: {
            display: 'block',
          },
        },
      },
      pre: {
        margin: '0 -1rem 2.5rem -1rem',
        fontSize: 15,
      },
      '.codeStyle': {
        padding: '1.5rem 0 1.5rem 1.5rem !important',
        overflow: 'scroll',
        borderRadius: 5,
        //background: 'transparent !important',
        //backgroundColor: 'var(--code-bg) !important',
        code: {
          paddingRight: '1.5rem',
          //backgroundColor: 'transparent !important',
          transform: 'translateZ(0)',
          minWidth: '100%',
          float: 'left',
          '& > span[data="highlight"]': {
            display: 'block',
            '&:last-of-type': {
              display: 'none',
            },
          },
        },
        '@media(max-width: 768px)': {
          borderRadius: '0 !important',
        },
      },
      code: {
        wordWrap: 'break-word',
        color: primaryColor,
        borderRadius: 5,
        '&::before, &::after': {
          content: '"`"',
          color: primaryColor,
        },
        '@media(max-width: 768px)': {
          fontSize: 16,
        },
      },
      'p code': {
        textShadow: 'none !important',
      },
      'pre code': {
        fontFamily: 'var(--font-primary) !important',
        '&::before, &::after': { content: 'none' },
      },
      'h3 code': {
        color: 'inherit',
      },
      'span.linenumber': {
        display: 'none !important',
      },
      '[data="highlight"]': {
        background: codeHighLightBackground,
        margin: '0 -1.5rem',
        padding: '0 1.5rem',
      },
      a: {
        color: primaryColor,
      },
      '.btn': {
        fontSize: '16px',
        background: primaryColor,
        padding: '10px 40px',
        color: '#fff',
        borderRadius: '5px',
      },
      '.btn.btn-secondary': {
        background: 'green',
      },
    }),
  },
})
