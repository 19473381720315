import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { ChakraProvider } from '@chakra-ui/react'
import Head from 'next/head'

import { useApollo } from 'lib/apollo/client'
import { theme } from 'lib/theme'
import { useRouter } from 'next/router'
import { debounce } from 'lodash'
import NProgress from 'nprogress'
import { pageview } from 'lib/ga'
import 'nprogress/nprogress.css'

// Only show NProgress after 500ms (slow loading)
const start = debounce(NProgress.start, 500)

export default function App(props: any) {
  const { Component, pageProps } = props
  const apolloClient = useApollo(pageProps.initialApolloState)

  const router = useRouter()

  React.useEffect(() => {
    const handleRouteChangeStart = start
    const handleRouteChangeComplete = (url: string) => {
      start.cancel()
      NProgress.done()
      window.scrollTo(0, 0)
      pageview(url)
    }
    const handleRouteChangeError = () => {
      start.cancel()
      NProgress.done()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [router.events])

  const getLayout = Component.getLayout ?? ((page: any) => page)
  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <ChakraProvider theme={theme}>
        <ApolloProvider client={apolloClient}>{getLayout(<Component {...pageProps} />)}</ApolloProvider>
      </ChakraProvider>
    </>
  )
}
