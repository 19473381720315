// don't import files or modules into this file
const { APP_ENV } = process.env

const {
  NEXT_PUBLIC_APP_NAME = 'Camkode',
  NEXT_PUBLIC_APP_DOMAIN = 'camkode.com',
  NEXT_PUBLIC_APP_URL = 'http://localhost:3000',

  NEXT_PUBLIC_FACEBOOK_ADMIN_ID = 123,
  NEXT_PUBLIC_FACEBOOK_APP_ID = 123,
  NEXT_PUBLIC_FACEBOOK_PAGE_ID = 123,
  NEXT_PUBLIC_FACEBOOK_REDIRECT_URI = 'http://localhost',
  NEXT_PUBLIC_FACEBOOK_USERNAME = 'camkode',

  NEXT_PUBLIC_GOOGLE_CLIENT_ID = 'googleclient',
  NEXT_PUBLIC_GA_TRACKING_ID = 'UA-52222352-1',
  NEXT_PUBLIC_GOOGLE_REDIRECT_URI = 'http://localhost',

  SHOW_ADS = true,
} = process.env
let env = APP_ENV as 'production' | 'development'

if (!env) {
  const hostname = typeof window !== 'undefined' && window?.location?.hostname
  env = 'development'
  if (hostname) {
    if (hostname.includes('camkode')) {
      env = 'production'
    }
  }
}

export const IS_SHOW_ADS = SHOW_ADS === true || SHOW_ADS === 'true'
export const IS_PRODUCTION = env === 'production'
export const IS_DEV = !IS_PRODUCTION
export const REDIRECT_PATH = 'redirect'
export const REDIRECT_REFRESH_KEY = 'session_expired'

export const SENTRY_DSN = 'https://5d0371a223bb4509902f8940cb957daf@o204549.ingest.sentry.io/5741383'

export const GRAPHQL_API_URL = IS_PRODUCTION ? 'https://camkode.com/graphql' : 'http://localhost:5556/graphql'

export const APP_NAME = NEXT_PUBLIC_APP_NAME
export const APP_DOMAIN = NEXT_PUBLIC_APP_DOMAIN
export const API_URL = IS_PRODUCTION ? 'https://camkode.com' : 'http://localhost:5556/graphql'

export const WEB_URL = NEXT_PUBLIC_APP_URL
export const APP_URL = NEXT_PUBLIC_APP_URL

export const ACCESS_TOKEN = 'camkode.access.token'
export const REFRESH_TOKEN = 'camkode.refresh.token'
export const LOGIN_TOKEN_KEY = 'token'
export const LOGIN_REFRESH_TOKEN_KEY = 'refreshToken'

export const PER_PAGE = 10
export const SITEMAP_PAGE_SIZE = 1000

export const META_KEYWORDS = 'camkode, web development'
export const META_DESCRIPTION =
  'Learn about web development and technology. Detailed guides for React, Next.js, TypeScript, VueJS, Nuxt, and Markdown projects.'
export const APP_IMAGE = `${WEB_URL}/assets/images/camkode-image.png`

export const FACEBOOK_ADMIN_ID = NEXT_PUBLIC_FACEBOOK_ADMIN_ID
export const FACEBOOK_APP_ID = NEXT_PUBLIC_FACEBOOK_APP_ID
export const FACEBOOK_PAGE_ID = NEXT_PUBLIC_FACEBOOK_PAGE_ID
export const FACEBOOK_REDIRECT_URI = NEXT_PUBLIC_FACEBOOK_REDIRECT_URI
export const FACEBOOK_USERNAME = NEXT_PUBLIC_FACEBOOK_USERNAME

export const GOOGLE_CLIENT_ID = NEXT_PUBLIC_GOOGLE_CLIENT_ID
export const GA_TRACKING_ID = NEXT_PUBLIC_GA_TRACKING_ID
export const GOOGLE_REDIRECT_URI = NEXT_PUBLIC_GOOGLE_REDIRECT_URI
